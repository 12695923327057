import React from 'react';
import { Link } from 'react-router';
import {
  Grid,
  Message,
  Header,
  Segment,
  Popup,
  Label,
  Icon,
} from 'semantic-ui-react';

import AmsDateFormatters from '../../utils/AmsDateFormatters';
import { allowedView } from '../../utils/EnforceRole';
import { acl } from '../../config';
import _, { orderBy } from 'lodash';

const newFormReviewTypes = ['RAN', 'Special', 'Follow-up'];

export const approvedBox = (
  <div
    style={{
      fontSize: '10px',
      fontFamily: 'PublicSans-Bold',
      float: 'right',
      borderRadius: '3px',
      border: 'solid 1px #007a26',
      backgroundColor: '#f5fff8',
      color: '#007a26',
      textAlign: 'center',
      display: 'inline-block',
      height: '28px',
      width: '90px',
      marginRight: '5px',
      paddingTop: '3px',
      marginTop: '-3px',
    }}
  >
    <Icon name="check circle" color="green"></Icon>
    APPROVED
  </div>
);

export const needClarificationBox = (
  <div
    style={{
      fontSize: '10px',
      fontFamily: 'PublicSans-Bold',
      float: 'right',
      borderRadius: '3px',
      border: 'solid 1px rgb(189,152,77)',
      backgroundColor: 'rgb(254,248,222)',
      color: 'rgb(189,152,77)',
      textAlign: 'center',
      display: 'inline-block',
      height: '28px',
      width: '120px',
      marginRight: '5px',
      paddingTop: '3px',
      marginTop: '-3px',
    }}
  >
    NEED CLARIFICATION
  </div>
);

export const inProgressBox = (
  <div
    style={{
      fontSize: '10px',
      fontFamily: 'PublicSans-Bold',
      float: 'right',
      borderRadius: '3px',
      border: 'solid 1px rgb(69,138,204)',
      backgroundColor: 'rgb(225,239,253)',
      color: 'rgb(69,138,204)',
      textAlign: 'center',
      display: 'inline-block',
      height: '28px',
      width: '90px',
      marginRight: '5px',
      paddingTop: '3px',
      marginTop: '-3px',
    }}
  >
    IN PROGRESS
  </div>
);

export const rejectedBox = (
  <div
    style={{
      fontSize: '10px',
      float: 'right',
      fontFamily: 'PublicSans-Bold',
      borderRadius: '3px',
      border: 'solid 1px #d91919',
      backgroundColor: '#fff5f5',
      color: '#d91919',
      textAlign: 'center',
      display: 'inline-block',
      height: '28px',
      width: '90px',
      marginRight: '5px',
      paddingTop: '3px',
      marginTop: '-3px',
    }}
  >
    <Icon
      style={{
        float: 'left',
        lineHeight: 'inherit',
        padding: '1px 0px 0px 10px',
      }}
      name="close"
      color="red"
    ></Icon>
    REJECTED
  </div>
);

export const redirectToTasksSummary = props => {
  props.router.push('/task');
};

export const generateReviewDetails = data => {
  const divStyle = { marginRight: '3em' };
  const boldStyle = { fontFamily: 'PublicSans-Bold' };
  if (!data || !data.reviewType) {
    return null;
  }

  const addComment = `/add-comment?key=${data.hashContent}`;
  const reportLink = (
    <a
      href={addComment}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Report Link for ${data.reviewId}`}
    >
      Report Link
    </a>
  );

  return (
    <>
      <span style={divStyle}>
        <span style={boldStyle}>Review Type: </span>
        {data.reviewType}
      </span>
      <span style={divStyle}>
        <span style={boldStyle}>Review Status: </span>
        {data.reviewStatus}
      </span>
      <span style={divStyle}>
        <span style={boldStyle}>Review Outcome: </span>
        {data.reviewOutcome}
      </span>
      <span style={divStyle}>
        <span style={boldStyle}>State: </span>
        {data.state}
      </span>
      <span style={divStyle}>
        <span style={boldStyle}>Agency ID: </span>
        {data.agencyId}
      </span>
      <span style={divStyle}>
        <span style={boldStyle}>Start Date: </span>
        {AmsDateFormatters.formatDate(data.reviewStartDate)}
      </span>

      <span style={divStyle}>
        <span style={boldStyle}>Due Date: </span>
        {AmsDateFormatters.formatDate(data.reviewEndDate)}
      </span>
      <span style={divStyle}>
        <span style={boldStyle}> {reportLink} </span>
      </span>
    </>
  );
};

export const hideStrongPractices = user => {
  return allowedView(acl.actions.task.hideStrongPractices, user.roles);
};

export const disableStrongPracticesComment = user => {
  return allowedView(
    acl.actions.task.disableStrongPracticesComment,
    user.roles
  );
};

export const hasFindings = item => {
  for (var pa in item?.taskPerformanceAreas)
    for (var f in item?.taskPerformanceAreas[pa]?.findingFeedBacks)
      if (
        item?.taskPerformanceAreas[pa]?.findingFeedBacks[f].collectDataType ==
        'finding'
      )
        return true;
  return false;
};

export const getContentAreaFindings = item => {
  let findings = [];
  for (var pa in item?.taskPerformanceAreas)
    for (var f in item?.taskPerformanceAreas[pa]?.findingFeedBacks)
      findings.push(item?.taskPerformanceAreas[pa]?.findingFeedBacks[f]);

  return orderBy(findings, ['collectDataType'], ['asc']);
};

export const renderFindingsAndPracticesCount = (data, hideStrongPractices) => {
  const pStyle = {
    padding: '8px',
    borderRadius: 4,
    margin: '0 10px 0 0',
    fontWeight: '600',
    color: '#fff',
    display: 'inline-block',
  };
  const findingsCount = data?.findingsCount;
  const strongPractices = data?.strongPracticeCount;
  return (
    <>
      {findingsCount > 0 ? (
        <p style={{ ...pStyle, background: '#205493' }}>
          Findings: {findingsCount}
        </p>
      ) : null}
      {strongPractices > 0 && !hideStrongPractices ? (
        <p style={{ ...pStyle, background: '#2e8540' }}>
          Strong Practices: {data?.strongPracticeCount}
        </p>
      ) : null}
    </>
  );
};
