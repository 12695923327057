import React from 'react';
import { isEmpty } from 'lodash';
import AmsPopup from '../../../../utils/AmsPopup';
import DOMPurify from 'dompurify';

function TQAnswerWidget(props) {
  const {
    options,
    value,
    required,
    disabled,
    readonly,
    autofocus,
    onBlur,
    onFocus,
    onChange,
    id,
  } = props;
  // Generating a unique field name to identify this set of radio buttons
  const name = Math.random().toString();
  const { enumOptions, enumDisabled, inline } = options;
  // checked={checked} has been moved above name={name}, As mentioned in #349;
  // this is a temporary fix for radio button rendering bug in React, facebook/react#7630.
  if (
    'Follow-up' === props?.formContext.reviewType &&
    '<div>Were there findings outside the protocol?</div>' ===
      props?.schema?.description
  ) {
    return <></>;
  }

  let descriptionValue = props.schema.description || '';
  if (descriptionValue && descriptionValue.length > 0) {
    descriptionValue = descriptionValue.replace(
      /<strong>/gi,
      '<b style="color: rgb(0, 0, 0, 1);">'
    );
    descriptionValue = descriptionValue.replace(/<\/strong>/gi, '</b>');
  }
  return (
    <>
      <p
        className="tq-answer-description"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(descriptionValue),
        }}
      />
      {citations(props)}
      {referenceNotes(props)}
      <div className="field-radio-group" id={id}>
        {enumOptions.map((option, i) => {
          const checked = option.value === value;
          const itemDisabled =
            enumDisabled && enumDisabled.indexOf(option.value) != -1;
          const disabledCls =
            disabled || itemDisabled || readonly ? 'disabled' : '';
          const radio = (
            <span>
              <input
                type="radio"
                checked={checked}
                name={name}
                required={required}
                value={option.value}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && i === 0}
                onChange={_ => onChange(option.value)}
                onBlur={onBlur && (event => onBlur(id, event.target.value))}
                onFocus={onFocus && (event => onFocus(id, event.target.value))}
              />
              <span>{option.label}</span>
              {option.label == 'Yes' ? guidance(props) : null}
            </span>
          );

          return inline ? (
            <label key={i} className={`radio-inline ${disabledCls}`}>
              {radio}
            </label>
          ) : (
            <div key={i} className={`radio ${disabledCls}`}>
              <label>{radio}</label>
            </div>
          );
        })}
      </div>
    </>
  );
}

function referenceNotes(props) {
  let value = props.schema.reference_notes;
  if (value && value.length > 0) {
    value = value.replace(/<strong>/gi, '<b style="color: rgb(0, 0, 0, 1);">');
    value = value.replace(/<\/strong>/gi, '</b>');
  }
  return !isEmpty(value) ? (
    <div style={{ marginTop: '10px' }}>
      <span style={{ color: 'rgba(0, 0, 0, 0.7)', textAlign: 'left' }}>
        <b
          style={{
            color: 'rgba(0, 0, 0, 1)',
            float: 'left',
            marginRight: '2px',
          }}
        >
          {'Reference Notes: '}
        </b>{' '}
        <span
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
        ></span>
      </span>
    </div>
  ) : (
    <></>
  );
}

function guidance(props) {
  let value = props.schema.guidance;
  if (value && value.length > 0) {
    value = value.replace(/<strong>/gi, '<b style="color: rgb(0, 0, 0, 1);">');
    value = value.replace(/<\/strong>/gi, '</b>');
  }
  var labelClass = 'guidance-form-field ' + props.schema.className;
  return !isEmpty(value) ? (
    <div className={labelClass} style={{ marginTop: '5px' }}>
      <span style={{ color: 'rgba(0, 0, 0, 0.7)', textAlign: 'left' }}>
        <b
          style={{
            color: 'rgba(0, 0, 0, 1)',
            float: 'left',
            marginRight: '2px',
          }}
        >
          {'Yes Guidance: '}
        </b>{' '}
        <span
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
        ></span>
      </span>
    </div>
  ) : (
    <></>
  );
}

function citations(props) {
  var labelClass = 'citations-form-field ' + props.schema.className;
  return props.schema.citations?.length > 0 ? (
    <div className={labelClass}>
      {props.schema.citations?.map((c, idx) => (
        <a
          key={idx}
          className="citation-url"
          target="_blank"
          href={'/review/citations/definition?citation=' + c}
        >
          {c}
        </a>
      ))}
    </div>
  ) : (
    <></>
  );
}

TQAnswerWidget.defaultProps = {
  autofocus: false,
};

export default TQAnswerWidget;
