import axios from 'axios';
import {
  REVIEW_SUBMISSIONS_FETCHED,
  REVIEW_SUBMISSIONS_CLEARED,
  REVIEW_GUIDES_FETCHED,
  REVIEW_CONTENT_AREAS_PERFORMANCE_AREAS_FETCHED,
  REVIEW_PERFORMANCE_MEASURES_FETCHED,
  REVIEW_OCI_SUMMARY_FETCHED,
  REVIEW_FOLLOWUP_SUBMISSIONS,
  REVIEW_FU_SUBMISSIONS_FETCHED,
  SURVEY_SUBMISSIONS_FETCHED,
  SURVEY_GUIDES_FETCHED,
  SURVEY_CITATIONS_FETCHED,
  SURRVEY_GUIDE_DETAILS_FETCHED,
} from '../../redux/types';

export const reviewSubmissionsFetched = reviewSubmissions => ({
  type: REVIEW_SUBMISSIONS_FETCHED,
  reviewSubmissions,
});

export const surveySubmissionsFetched = surveySubmissions => ({
  type: SURVEY_SUBMISSIONS_FETCHED,
  surveySubmissions,
});

export const reviewFUSubmissionsFetched = reviewFUSubmissions => ({
  type: REVIEW_FU_SUBMISSIONS_FETCHED,
  reviewFUSubmissions,
});

export const reviewContentAreasFetched = contentAreas => ({
  type: REVIEW_CONTENT_AREAS_PERFORMANCE_AREAS_FETCHED,
  contentAreas,
});

export const reviewSubmissionsCleared = () => ({
  type: REVIEW_SUBMISSIONS_CLEARED,
});

export const reviewGuidesFetched = guides => ({
  type: REVIEW_GUIDES_FETCHED,
  guides,
});

export const surveyGuidesFetched = guides => ({
  type: SURVEY_GUIDES_FETCHED,
  guides,
});

export const followupSurveyCitationsFetched = followupCitations => ({
  type: SURVEY_CITATIONS_FETCHED,
  followupCitations,
});

export const surveyGuideDetailsFetched = surveyGuideDetails => ({
  type: SURRVEY_GUIDE_DETAILS_FETCHED,
  surveyGuideDetails,
});

export const reviewPerformanceMeasuresFetched = performanceMeasures => ({
  type: REVIEW_PERFORMANCE_MEASURES_FETCHED,
  performanceMeasures,
});

export const reviewOCISummaryDetailsFetched = summaryDetails => ({
  type: REVIEW_OCI_SUMMARY_FETCHED,
  summaryDetails,
});

export const setFollowupSubmission = submission => ({
  type: REVIEW_FOLLOWUP_SUBMISSIONS,
  submission: submission,
});

export const fetchReviewSubmissions = requestInput => dispatch =>
  axios
    .post('/review/fetchReviewSubmissions', requestInput)
    .then(response => dispatch(reviewSubmissionsFetched(response.data)));

export const clearReviewSubmissions = () => dispatch =>
  dispatch(reviewSubmissionsCleared());

export const fetchReviewGuides = requestInput => dispatch =>
  axios
    .post('/review/fetch-review-guides', requestInput)
    .then(response => dispatch(reviewGuidesFetched(response.data)));

export const fetchReviewPerformanceMeasures = requestInput => dispatch =>
  axios
    .post('/review/fetch-review-performance-measures', requestInput)
    .then(response =>
      dispatch(reviewPerformanceMeasuresFetched(response.data))
    );

export const fetchReviewPerformanceAreas = requestInput => dispatch =>
  axios
    .post('/review/fetch-review-performance-areas', requestInput)
    .then(response =>
      dispatch(reviewPerformanceMeasuresFetched(response.data))
    );

export const fetchReviewContentAreas = requestInput => dispatch => {
  axios
    .post('/review/fetch-review-contentareas', requestInput)
    .then(response => dispatch(reviewContentAreasFetched(response.data)));
};

export const fetchReviewOCISummary = requestInput => dispatch => {
  return axios
    .post('/review/ociSummaryReport', requestInput)
    .then(response => dispatch(reviewOCISummaryDetailsFetched(response.data)));
};

export const fetchFollowupSubmission = reviewId => dispatch => {
  return axios
    .post('/review/followupSubmissions', reviewId)
    .then(response =>
      dispatch(setFollowupSubmission({ submission: response.data }))
    )
    .catch(err => console.error(err));
};

export const fetchFUReviewSubmissions = requestInput => dispatch =>
  axios
    .post('/review/fetchFUReviewSubmissions', requestInput)
    .then(response => dispatch(reviewFUSubmissionsFetched(response.data)));

export const fetchFUSurveyDetails = requestInput => dispatch =>
  axios
    .post('/review/fetchFUSurveyDetails', requestInput)
    .then(response => dispatch(reviewFUSubmissionsFetched(response.data)));

export const fetchSurveySubmissions = requestInput => dispatch =>
  /*if (requestInput.fiscalYear === 2025) {
    axios
      .post('/review/fetchSurveySubmissionDetails', requestInput)
      .then(response => dispatch(surveySubmissionsFetched(response.data)));
  } else {*/
  axios
    .post('/review/fetchSurveySubmissions', requestInput)
    .then(response => dispatch(surveySubmissionsFetched(response.data)));

export const fetchSurveyGuides = requestInput => dispatch =>
  axios
    .post('/review/fetch-survey-guides', requestInput)
    .then(response => dispatch(surveyGuidesFetched(response.data)));

export const fetchFollowupSurveyCitations = requestInput => dispatch =>
  axios
    .post('/review/fetch-followup-survey-citations', requestInput)
    .then(response => dispatch(followupSurveyCitationsFetched(response.data)));

export const fetchSurveyGuidesDetails = requestInput => dispatch =>
  axios
    .post('/review/fetch-survey_guide_details', requestInput)
    .then(response => dispatch(surveyGuideDetailsFetched(response.data)));
