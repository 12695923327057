import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';
import queryString from 'query-string';

// Import sytle.
import './assets/style.css';

// Import Actions.
import {
  fetchReportList,
  fetchAssigneesFilter,
} from '../../actions/reportActions';

import { fetchUsers, fetchFOMUsers } from '../../actions/userActions';
import { fetchWorkflowStatus } from '../../actions/workflowActions';

// Import utils
import SlidingContainer from '../../utils/layout/SlidingContainer';

// Import components.
import ReportItems from './ReportItems';
import Workflow from './../Workflow/Workflow';
import AmsSkipLinks from '../../utils/AmsSkipLinks';
import SummaryFilters from '../Shared/FilterHelpers/SummaryFilters';
import { isArray } from 'underscore';

class ReportPage extends Component {
  constructor() {
    super();

    let month = new Date().getMonth();
    let year = new Date().getYear() + 1900;
    // fiscal year starts each year on Oct 1st
    if (month > 8) {
      year = year + 1;
    }

    this.state = {
      selectedFilters: [],
      pageNum: 1,
      pageSize: 25,
      orderBy: 'dueDate',
      sortOrder: 'asc',
      queryFilters: {
        selectedFilters: [],
        queryFilters: {},
        selectedGrantees: [],
        responseErrors: [],
        reviewTypes: [],
        reviewIdList: [],
        region: '',
        fomFilter: '',
        year: [year],
        status: '',
      },
    };
    this.filterSegment = React.createRef();
  }

  getData() {
    const { currentUser, isAuthenticated } = this.props;
    const { pageNum, pageSize, orderBy, queryFilters } = this.state;
    const {
      reviewIdList,
      reviewTypes,
      selectedGrantees,
      region,
      fomFilter,
      year,
      status,
    } = this.state.queryFilters;

    if (isAuthenticated) {
      const requestInput = {
        userId: currentUser.oid,
        pageNum: pageNum,
        pageSize: pageSize,
        orderBy,
        filters: {
          reviewTypes: reviewTypes,
          granteeIdList: selectedGrantees,
          reviewIdList: reviewIdList,
          region: region,
          fomFilter: fomFilter,
          year: year,
          status: status,
        },
      };
      if (queryFilters.reportStage) {
        requestInput.filters.reportStage = queryFilters.reportStage;
      }

      this.props.fetchReportList(requestInput).catch(error => {
        if (error.response) {
          this.setState({
            responseErrors: [
              ...(this.state.responseErrors && this.state.responseErrors),
              error.response.data,
            ],
          });
        } else {
          this.setState({
            responseErrors: [
              ...(this.state.responseErrors && this.state.responseErrors),
              'Unknown error',
            ],
          });
        }
      });
    }
  }

  applyFilter(filter) {
    if (!_.isEmpty(filter)) {
      let newFilters = { ...this.state.queryFilters, status: filter };
      this.setState({ queryFilters: newFilters }, () => this.getData());
      return;
    }
  }

  componentDidMount() {
    const { workflowStatus } = this.props;

    // Only get status if not already fetched.
    // if (_.isEmpty(workflowStatus)) {
    //   this.props.fetchWorkflowStatus();
    // }

    this.props.fetchAssigneesFilter({});
    this.props.fetchFOMUsers();

    const { search } = this.props.location;

    const filterQueryString =
      search &&
      queryString.parse(search, {
        arrayFormat: 'bracket',
      });
    if (filterQueryString.year) {
      filterQueryString['year'] = filterQueryString['year'].map(i => Number(i));
    }

    this.setState(
      {
        queryFilters: {
          ...this.state.queryFilters,
          ...filterQueryString,
        },
      },
      () => this.getData()
    );
  }

  componentWillMount() {
    this.props.fetchUsers();
  }

  handleFilterSelect(filter, event) {
    const { selectedFilters } = this.state;

    if (event.target.checked) {
      this.setState({
        selectedFilters: [...selectedFilters, ...[filter]],
      });
    } else {
      this.setState({
        selectedFilters: _.filter(selectedFilters, item => {
          return item !== filter;
        }),
      });
    }
  }

  handleFilterSubmit = f => {
    const { currentUser, isAuthenticated } = this.props;
    const { pageNum, pageSize, orderBy, queryFilters } = this.state;
    const { status } = this.state.queryFilters;

    if (isAuthenticated) {
      const requestInput = {
        userId: currentUser.oid,
        pageNum: pageNum,
        pageSize: pageSize,
        orderBy,
        filters: {
          reviewTypes: f.reviewTypes,
          granteeIdList: f.selectedGrantees,
          reviewIdList: f.reviewIdList,
          region: f.region,
          fomFilter: f.fomFilter,
          year: isArray(f.year) ? f.year.map(i => Number(i)) : f.year,
          status: status,
        },
      };

      let queryFiltersCopy = { ...queryFilters };
      let keys = Object.keys(queryFilters);
      let diff = false;
      for (let i = 0; i < keys.length; i++) {
        let curr = keys[i];
        if (
          (queryFiltersCopy[curr] || queryFiltersCopy[curr] === '') &&
          (requestInput.filters[curr] || requestInput.filters[curr] === '') &&
          !_.isEqual(queryFiltersCopy[curr], requestInput.filters[curr])
        ) {
          diff = true;
          queryFiltersCopy[curr] = requestInput.filters[curr];
        }
      }

      if (diff) {
        this.setState({
          ...this.state,
          queryFilters: {
            ...queryFiltersCopy,
          },
        });
      }

      if (queryFilters.reportStage) {
        requestInput.filters.reportStage = queryFilters.reportStage;
      }

      this.props.fetchReportList(requestInput).catch(error => {
        if (error.response) {
          this.setState({
            responseErrors: [
              ...(this.state.responseErrors && this.state.responseErrors),
              error.response.data,
            ],
          });
        } else {
          this.setState({
            responseErrors: [
              ...(this.state.responseErrors && this.state.responseErrors),
              'Unknown error',
            ],
          });
        }
      });
    }
  };

  scrollToRef = () => {
    window.scrollTo(0, this.filterSegment.current.offsetTop);
    this.filterSegment.current.focus();
  };

  handleFilterReset = () => {
    let month = new Date().getMonth();
    let year = new Date().getYear() + 1900;
    // fiscal year starts each year on Oct 1st
    if (month > 8) {
      year = year + 1;
    }
    this.setState(
      {
        queryFilters: {
          selectedGrantees: [],
          responseErrors: [],
          reviewTypes: [],
          reviewIdList: [],
          region: '',
          fomFilter: '',
          year: [year],
          status: '',
        },
        pageSize: 10,
      },
      () => {
        this.handleFilterSubmit(this.state.queryFilters);
        this.scrollToRef();
      }
    );
  };

  renderReportFilters() {
    const { year } = this.state.queryFilters;

    const defaultFilters = [
      {
        key: 'reviewId',
        value: true,
      },
      {
        key: 'selectedGrantees',
        value: true,
      },
      {
        key: 'fiscalYear',
        value: true,
      },
    ];

    return (
      <div ref={this.filterSegment} tabIndex="-1">
        <Segment>
          {/* <div className="page-filters"> */}
          <AmsSkipLinks
            links={[{ title: 'Skip filters and go to results', to: 'results' }]}
          />

          <Workflow
            type="report"
            title="Review Reports by Status"
            applyFilter={this.applyFilter.bind(this)}
            bubble={this.state.queryFilters.status}
          />

          <SummaryFilters
            page="Report"
            year={year}
            defaultFilters={defaultFilters}
            data={{
              handleFilterSubmit: this.handleFilterSubmit,
              handleFilterReset: this.handleFilterReset,
            }}
            {...this.props}
          />
          {/* </Well> */}

          {/* </div> */}
        </Segment>
      </div>
    );
  }

  renderReportList() {
    const { reports, users, total } = this.props;
    const { reviewTypes } = this.props.lookups || {};
    if (reviewTypes && users && !this.reportsRequested) {
      this.reportsRequested = true;
      this.getData();
    }

    let actions = {
      assignUser: function(ev, cell, row) {
        let _self = this;
        axios
          .post('/report/assignment', {
            reviewID: row.reviewId,
            reportID: row.reportId,
            reportStatus: row.teamStatus,
            toWho:
              (
                this.props.users.users.filter(
                  u => u.oid === ev.target.value
                )[0] || {}
              ).oid || null,
            byWho: this.props.currentUser.oid,
            fromWho:
              (this.props.users.users.filter(u => u.oid === cell)[0] || {})
                .oid || null,
            when: new Date().toISOString(),
          })
          .then(
            response => {
              _self.getData();
            },
            err => {
              console.error(err);
            }
          );
      }.bind(this),
      teamStatusUpdate: function(ev, cell, row) {
        let _self = this;
        let status = ev || '';
        axios
          .patch('/report/updateReportStage', {
            reviewID: row.reviewId,
            reportID: row.reportId || undefined,
            fromStage: row.reportStage || '',
            toStage: status,
            byWho: this.props.currentUser.oid,
            when: new Date().toISOString(),
          })
          .then(
            response => {
              _self.getData();
            },
            err => {
              console.error(err);
            }
          );
      }.bind(this),
    };
    if (reports) {
      return (
        <ReportItems
          filters={this.state.queryFilters}
          reports={reports}
          fetchReports={this.props.fetchReportList}
          total={total}
          users={users}
          actions={actions}
          assignees={this.props.fetchedAssigneesFilterList}
          lookups={this.props.lookups}
          updatePageSize={this.updatePageSize}
          pageSize={this.state.pageSize}
        />
      );
    }
  }

  updatePageSize = pageSize => {
    this.setState({
      pageSize,
    });
  };

  showResponseErrors() {
    const { responseErrors } = this.state;

    if (responseErrors && responseErrors.length)
      return (
        <Alert bsStyle="danger">
          <strong>Something went wrong!</strong>
          <ul>
            {responseErrors.map((errorObject, index) => (
              <li key={index}>
                {errorObject.message} code:(
                {errorObject.code})
              </li>
            ))}
          </ul>
        </Alert>
      );
  }

  render() {
    return (
      <div className="report-page">
        {this.showResponseErrors()}

        <SlidingContainer calendar>
          {this.renderReportFilters()}

          <div className="ams-table" role="search">
            {this.renderReportList()}
          </div>
        </SlidingContainer>
      </div>
    );
  }
}

ReportPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  reports: PropTypes.array,
  fetchReportList: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  workflowStatus: PropTypes.object.isRequired,
  fetchWorkflowStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    reports: state.report.reports.data,
    total: state.report.reports.totalCount,
    users: state.users,
    lookups: state.lookups,
    workflowStatus: state.workflow.workflowStatus,
    fetchedAssigneesFilterList: state.report.fetchedAssigneesFilterList,
    fomUsers: state.users.fomUsers,
  };
};

export default connect(mapStateToProps, {
  fetchReportList,
  fetchUsers,
  fetchWorkflowStatus,
  fetchAssigneesFilter,
  fetchFOMUsers,
})(ReportPage);
